import React from "react";
import "./About2.css";

import foto from "../../images/head.gif";
import title from "../../images/title.png";

const About2 = () => {
  return (
    <div className="A2Super" id="about2">
      <div className="A2Container">
        {/* <div className="A2Left">
          <img src={foto} />
        </div> */}
        <div className="A2Right">
          {/* <h1>
            Arbi<span>trump</span>
          </h1>
          <h2 className="H2Span">
            Lets make <span>avalanche</span> great again!
          </h2> */}
          <img src={title} alt="" />
          <div className="DarkSquare">
            <p>
            Schrödingers <span>cat</span> is alive
              <br></br>
              Join <span>Schrödingers</span>!
              <br />
              <br />
              💵 Experienced team
              <br />
              💵 Long term vision
              <br />
              <br />
              $Schrödingers Cat <span>will show</span> you 
               <span> the future</span> of blockchain.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About2;

import React from "react";
import "./Hero.css";

import dc from "../../images/pngtnt.png";

const Hero = () => {
  return (
    <div className="HeroSuper" id="hero">
      <div className="Herocontainer">
        <h1>
          <span>Schrödingers Cat</span>:
        </h1>
        <h2>Quantum Mechanics</h2>
        <p>
        Every day we are one step closer to our ultimate destiny..
          <br />
          <br /> Schrödinger's cat is a thought experiment, sometimes described as a paradox, of quantum superposition.
           In the thought experiment, a hypothetical cat may be considered simultaneously
           both alive and dead, while it is unobserved in a closed box, as a result of its fate being linked to a random subatomic event <br />
        </p>
        <div className="HeroBtnContainer">
          <a
            href="https://raydium.io"
            target="_blank"
          >
            <div className="HeroBtn">
              BUY $schrodingers
            </div>
          </a>
        </div>
      </div>
      <img src={dc} alt="Logo" className="HeroImage" />
    </div>
  );
};

export default Hero;
